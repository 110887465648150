import React from "react";
import moment from "moment";
import Select from "react-select";


export const PRESET_LAST_30_DAYS = {
    from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
}

export const PRESET_ALL_TIME = {
    from: moment().subtract(100, 'years').startOf('year').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
}


export default function Presets({dateRange, setDateRange}) {
    const options = React.useMemo(() => [
        {
            label: 'present', options: [
                {
                    value: {
                        from: moment().startOf('month').format('YYYY-MM-DD'),
                        to: moment().endOf('month').format('YYYY-MM-DD')
                    }, label: 'This Month'
                },
                {
                    value: {
                        from: moment().startOf('day').format('YYYY-MM-DD'),
                        to: moment().endOf('day').format('YYYY-MM-DD')
                    }, label: 'Today'
                },
                {
                    value: {
                        from: moment().startOf('week').format('YYYY-MM-DD'),
                        to: moment().endOf('week').format('YYYY-MM-DD')
                    }, label: 'This Week'
                },
                {
                    value: {
                        from: moment().startOf('year').format('YYYY-MM-DD'),
                        to: moment().endOf('year').format('YYYY-MM-DD')
                    }, label: 'This Year'
                },
                {
                    value: PRESET_ALL_TIME,
                    label: 'All time'
                }
            ]
        },
        {
            label: 'Past', options: [
                {
                    value: {
                        from: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')
                    }, label: 'Yesterday'
                },
                {
                    value: {
                        from: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                    }, label: 'Last Week'
                },
                {
                    value: PRESET_LAST_30_DAYS, label: 'Last 30 Days'
                },
                {
                    value: {
                        from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                    }, label: 'Last Month'
                },
                {
                    value: {
                        from: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                    }, label: 'Last Year'
                },
            ]
        }
    ], [])

    const flatOptions = React.useMemo(() => options.reduce((acc, curr) => [...acc, ...curr.options], []), [options])

    const selectedOption = flatOptions.find(option => dateRange?.from && dateRange?.to && option.value.from === dateRange.from && option.value.to === dateRange.to);

    const customStyle = {
        option: provided => ({
            ...provided,
            fontSize: "0.8rem",
            lineHeight: "0.8rem"
        }),
        groupHeading: provided => ({
            ...provided,
            fontSize: "0.6rem",
            lineHeight: "0.6rem"
        }),
        control: provided => ({
            ...provided,
            minWidth: '8rem',
            border: 'none',
            boxShadow: 'none',
            fontWeight: !!selectedOption ? 'bold' : 'normal'
        })
    }
    return <>
        <Select
            styles={customStyle}
            className="react-select-container small"
            classNamePrefix="react-select"
            options={options}
            value={selectedOption || null}
            onChange={(selectedOption) => {
                setDateRange(selectedOption.value)
            }}
            placeholder={"Presets"}
            />
    </>
}