import React, {useContext, useEffect} from "react";
import DataTable from "react-data-table-component";
import {Button, Input} from "reactstrap";
import moment from "moment";
import TagFormDisplay from "../lead/common/tagFormDisplay";
import {FilterContext, TableFilterContext} from "../../../contexts";
import useDebounce, {toTitleCase} from "../../../util";
import {useNavigate} from "react-router-dom";
import PropertyGroupsDropDown from "./propertyGroupsDropDown";
import PropertyGroupsDisplay from "./propertyGroupsDisplay";
import TableTagGroupSelector from "./tableTagGroupSelector";
import {PRESET_LAST_30_DAYS} from "../../../dateRangeFilter/presets";
import MomentDisplay from "../../../momentDisplay";
import {useTagGroup} from "../../../fetchers/tags";
import {useLeads} from "../../../hooks";
import {createPortal} from "react-dom";
import ExportList from "../exportList";


export default function LeadsTable({disabledLeads, defaultSort = '-datetime_added', leadsPath = '.', exportButtonPortalRef}) {
    const navigate = useNavigate();
    const {filters: tableFilters, setFilters: setTableFilters} = useContext(TableFilterContext)
    const leadTableFilters = tableFilters?.leads || {}

    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroup({})

    const [page, setPage] = React.useState(1)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [sort, setSort] = React.useState(defaultSort)
    const [selectedTagGroupId, setSelectedTagGroupId] = React.useState(null)

    const {filters, setFilter} = React.useContext(FilterContext)

    const search = useDebounce(filters.leads?.search, 1000)
    const dateRange = useDebounce(filters.leads?.dateRange, 1000)

    const dateFrom = dateRange?.from || PRESET_LAST_30_DAYS.from
    const dateTo = dateRange?.to || PRESET_LAST_30_DAYS.to

    const {actualTagIds, propertyGroupIds} = React.useMemo(() => {
        const tagIds = filters.leads?.tag_ids
        return {
            actualTagIds: tagIds?.filter(row => !row.toString().includes('property')),
            propertyGroupIds: tagIds?.filter(row => row.toString().includes('property')).map(row => row.replace('property-', ''))
        }
    }, [filters.leads?.tag_ids])

    const otherFilters = React.useMemo(() => {
        const _tmp = {
            ...filters.leads,
            ...leadTableFilters
        }

        delete _tmp.search
        delete _tmp.dateRange
        delete _tmp.tag_ids
        return _tmp;
    }, [filters.leads, leadTableFilters])

    useEffect(() => {
        setPage(1)
    }, [search, dateFrom, dateTo, actualTagIds, propertyGroupIds, otherFilters, disabledLeads])

    const params = {
        page,
        page_size: rowsPerPage,
        search,
        datetime_added__gte: dateFrom,
        datetime_added__lte: moment(dateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        sort,
        tag_ids: actualTagIds,
        property_groups__id: propertyGroupIds,
        ...otherFilters
    }
    if (disabledLeads) {
        params.show_disabled = 1
        params.is_active = false
    } else {
        params.is_active = true
    }
    if (sort === '-datetime_updated') {
        params.datetime_updated__gte = params.datetime_added__gte
        params.datetime_updated__lte = params.datetime_added__lte
        delete params.datetime_added__gte
        delete params.datetime_added__lte
    }

    const {data: leadsResponse, isLoading} = useLeads(params)

    const mainColumn = React.useMemo(() => {
        return {
            name: <>
                <Input
                    type="select" size="sm" className="border-0 font-bold"
                    value={sort}
                    onChange={e => {
                        setSort(e.target.value)
                    }}>
                    <option value='-datetime_added'>Latest</option>
                    <option value='first_name,last_name'>A-Z</option>
                    <option value='-first_name,-last_name'>Z-A</option>
                    <option value='-datetime_updated'>Last Edited</option>
                </Input>
            </>,
            selector: row => row.id,
            format: row => {
                const addedMoment = moment(row.datetime_added)
                const updatedMoment = moment(row.datetime_updated)
                return <div className="pt-1 pb-3">
                    <Button
                        color="none" className="p-0 my-2 text-blue2"
                        onClick={() => {
                            navigate(`${leadsPath}/edit/${row.id}/`)
                        }}
                    >
                        <strong>{row.name}</strong>
                    </Button>
                    <br/>
                    <small>

                        <strong>ADDED:</strong>{" "}
                        <span className="text-muted">
                            <MomentDisplay momentData={addedMoment}/>
                        </span>

                        <br/>
                        {
                            (disabledLeads && row.deletion_reason_history?.datetime_added) ? <>
                                <strong>DISABLED:</strong>{" "}
                                <span className="text-muted">
                                    <MomentDisplay momentData={moment(row.deletion_reason_history.datetime_added)}/>
                                </span>
                            </> : <>
                                <strong>LAST EDIT:</strong>{" "}
                                <span className="text-muted">
                                    <MomentDisplay momentData={updatedMoment}/>
                                </span>
                            </>
                        }

                    </small>
                </div>
            }, sortable: false
        }
    }, [leadsPath, sort, disabledLeads])

    const propertyColumn = React.useMemo(() => {
        return {
            name: <PropertyGroupsDropDown
                selectedPropertyGroups={filters.leads?.property_groups__id?.[0] || null}
                setSelectedPropertyGroups={(propertyGroupIds) => {
                    const newValue = (propertyGroupIds?.[0] && propertyGroupIds?.[0] !== '0') ? propertyGroupIds?.[0] : undefined
                    setFilter('property_groups__id', newValue, 'leads')
                }}
            />,
            selector: row => row.property,
            format: row => {
                return <div className="p-2" style={{
                    whiteSpace: 'wrap'
                }}>
                    <PropertyGroupsDisplay selectedPropertyGroups={row.property_groups}/>
                </div>
            }, sortable: false
        }
    }, [filters.leads?.property_groups__id, setFilter])

    const tagColumn = React.useMemo(() => {
        return {
            name: <TableTagGroupSelector
                selected={selectedTagGroupId}
                setSelected={(tagGroupId) => {
                    setSelectedTagGroupId(tagGroupId)
                }}
            />,
            sortable: false,
            selector: row => row.form_tags,
            format: row => {
                return <div className="p-2" style={{
                    whiteSpace: 'wrap'
                }}>
                    {
                        row.form_tags.filter(tagForm => {
                            if (selectedTagGroupId === 'others' || !selectedTagGroupId) {
                                return true
                            } else {
                                return tagForm.tag_data.tag_group === selectedTagGroupId
                            }
                        }).filter(tagForm => {
                            const tagGroup = tagGroupsData?.find(row => row.id === tagForm.tag_data.tag_group)
                            const showTagGroup = tagGroup?.is_active
                            const showOnList = tagForm.tag_data.display_on_list && showTagGroup
                            const showOthers = selectedTagGroupId === 'others'

                            return (
                                (showOnList && !showOthers) || (!showOnList && showOthers)
                            ) && tagForm.tag_data.is_active
                        }).map(tagForm => {
                            return <TagFormDisplay
                                key={tagForm.id}
                                showText={false}
                                tagForm={tagForm}
                                isColorActive={true}
                                onClick={() => {
                                    if (!filters.leads?.tag_ids?.includes(tagForm.tag)) {
                                        setFilter('tag_ids', [
                                            ...filters.leads?.tag_ids || [],
                                            tagForm.tag
                                        ], 'leads')
                                    }
                                }}
                            />
                        })
                    }
                </div>
            }
        }
    }, [filters.leads?.tag_ids, selectedTagGroupId, setFilter, tagGroupsData])

    const columns = React.useMemo(() => {
        const _tmp = [
            mainColumn
        ]

        if (disabledLeads) {
            _tmp.push({
                name: "Reason",
                sortable: false,
                selector: row => row.deletion_reason_history.context.type,
                format: row => {
                    const reasonType = row.deletion_reason_history?.context?.type === 'delete' ?
                        'disabled' :
                        row.deletion_reason_history?.context?.type
                    return toTitleCase(reasonType)
                }
            })
            _tmp.push({
                name: "Disabled By",
                sortable: false,
                selector: row => row.deletion_reason_history?.updated_by_data?.name
            })
            _tmp.push({
                name: "Remarks",
                sortable: false,
                selector: row => row.deletion_reason_history?.remarks
            })
        } else {
            _tmp.push(propertyColumn)
            _tmp.push(tagColumn)
        }

        return _tmp
    }, [mainColumn, propertyColumn, tagColumn, disabledLeads])


    return <>
        <DataTable
        progressPending={isLoading}
        striped
        responsive
        persistTableHead
        pagination={true}

        columns={columns} data={leadsResponse?.results}

        onChangeRowsPerPage={(rows) => {
            setRowsPerPage(rows)
        }}
        onChangePage={(page) => {
            setPage(page)
        }}
        paginationTotalRows={leadsResponse?.count}

        paginationServer
        sortServer

    />
        {
            exportButtonPortalRef?.current && createPortal(<ExportList params={params} count={leadsResponse?.count}/>, exportButtonPortalRef.current)
        }
    </>
}