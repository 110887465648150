import {
    Button,
    ButtonGroup,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUserPlus, faUsers} from "@fortawesome/free-solid-svg-icons";
import DateRangeFilter from "../../dateRangeFilter";
import TagDisplay from "../../tagDisplay";
import TagFilter from "./lead/activities/filter/tagFilter";
import EditModal from "./lead/editModal";
import LeadsTable from "./leadsTable";
import React, {useContext, useState} from "react";
import {FilterContext, PermissionsContext, TableFilterContext} from "../../contexts";
import {useBrokerGroup, useBrokerPerson, useSalesPerson} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {PRESET_ALL_TIME} from "../../dateRangeFilter/presets";

export default function LeadsList() {
    const {filters: tableFilters, setFilters: setTableFilters} = useContext(TableFilterContext)
    const exportListPortalRef = React.useRef(null)

    const salesProfileId = tableFilters?.leads?.sales_profile || null;
    const {data: salesProfile, isLoading: isLoadingSalesProfile} = useSalesPerson({salesPersonId: salesProfileId})
    const {data: brokerProfile, isLoading: isLoadingBrokerProfile} = useBrokerPerson({
        brokerId: salesProfileId
    })

    const displayProfile = salesProfile || brokerProfile
    const isLoadingProfile = (isLoadingSalesProfile || isLoadingBrokerProfile) && !displayProfile

    const brokerGroupId = tableFilters?.leads?.sales_profile__group || null
    const {data: brokerGroup, isLoading: isLoadingBrokerGroup} = useBrokerGroup({brokerGroupId})

    const navigate = useNavigate()

    const {permissions} = useContext(PermissionsContext)
    const [addModalKey, setAddModalKey] = useState(null)
    const [showDisabledLeads, setShowDisabledLeads] = useState(false)

    const {filters, setFilters, setFilter} = useContext(FilterContext)

    const canViewDisabledLeads = permissions.includes('microsite.delete_saleslead')

    React.useEffect(() => {
        if (!filters.leads?.dateRange) {
            setFilter('dateRange', {...PRESET_ALL_TIME}, 'leads')
        }
    }, [filters.leads?.dateRange])


    return <>
        <Row className="mt-4 mb-5">
            <Col>
                <ButtonGroup>
                    <Button color="info-outline" onClick={() => setAddModalKey(Date.now())}>
                        <FontAwesomeIcon icon={faUserPlus}/>{" "}
                        ADD LEAD
                    </Button>
                    <ButtonGroup>
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="info-outline" className="ps-1 pe-2 border-start-0"/>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    navigate('./import')
                                }}>
                                    IMPORT LEADS
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                </ButtonGroup>
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Input
                    className="rounded p-3"
                    type="text" placeholder="Search"
                    value={filters.leads?.search}
                    onChange={(e) => setFilter('search', e.target.value, 'leads')}
                />
            </Col>
        </Row>

        {
            canViewDisabledLeads && <Row className={"justify-content-end"}>
                <Col className="col-auto">
                    <FormGroup switch>
                        <Input
                            type={"switch"}
                            checked={showDisabledLeads}
                            onChange={() => setShowDisabledLeads(prevState => !prevState)}
                        />
                        <Label check>
                            <small>View disabled/merged leads</small>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        }

        <Row className="mt-5 border-top">
            <DateRangeFilter
                dateRange={filters.leads?.dateRange}
                setDateRange={(dateRange) => setFilter('dateRange', dateRange, 'leads')}
            />
        </Row>

        <Row className="mt-2 py-3 border-top">
            <Col>
                <small style={{color: "#666"}} className="me-2">
                    <strong className="small">SHOWING:</strong>
                </small>
                {" "}
                {
                    !!salesProfileId && <TagDisplay
                        onDelete={() => {
                            setTableFilters('leads', 'sales_profile', undefined)
                        }}
                    >
                        <FontAwesomeIcon icon={faUser} className="me-2"/>
                        {isLoadingProfile ? "Loading..." : <>
                            {displayProfile.is_broker ? "BROKER" : "SALESPERSON"}: {displayProfile.profile.name}
                        </>}
                    </TagDisplay>
                }
                {
                    !!brokerGroupId && <TagDisplay
                        onDelete={() => {
                            setTableFilters('leads', 'sales_profile__group', undefined)
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} className="me-2"/>
                        BROKER GROUP: {isLoadingBrokerGroup ? "Loading..." : brokerGroup.name}
                    </TagDisplay>
                }
                <TagFilter
                    setActiveTagIds={(tagIds) => {
                        setFilter('tag_ids', tagIds, 'leads')
                    }}
                    activeTagIds={filters.leads?.tag_ids || []}
                    showSpecialTagGroup={false}
                    showPropertyGroups={true}
                />
            </Col>
            <Col className="col-auto">
                <Button size="sm" color="link" onClick={() => {
                    setFilters(prevState => {
                        const _tmp = {...prevState}
                        delete _tmp.leads
                        return _tmp
                    })
                    setShowDisabledLeads(false)
                }}>
                    Clear Filters
                </Button>
            </Col>
            <Col className="col-auto" >
                <div ref={exportListPortalRef}></div>
            </Col>
        </Row>

        {
            !!addModalKey && <EditModal
                key={addModalKey}
                toggle={() => {
                    setAddModalKey(null)
                }}
                isOpen={!!addModalKey}
            />
        }

        <Row>
            <Col>
                <LeadsTable
                    disabledLeads={showDisabledLeads}
                    exportButtonPortalRef={exportListPortalRef}
                />
            </Col>
        </Row>
    </>
}